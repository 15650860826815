import Vue from 'vue'
import Vuex from 'vuex'
import cache from '../api/cache'

Vue.use(Vuex)
const state = {
    login: '',
    authStatus:'',
    info:'',
    zhuce:true
}

const actions = {
    get_login({ commit, dispatch }) {
        const data = cache.getSession('login')
        if (data) {
            commit('set_login', data)
        } else {
            commit('set_login', '')
        }
    },
    get_authStatus({ commit, dispatch }) {
        const data = cache.getSession('authStatus')
        if (data) {
            commit('set_authStatus', data)
        } else {
            commit('set_authStatus', '')
        }
    },
    get_info({ commit, dispatch }) {
        const data = cache.getSession('info')
        if (data) {
            commit('set_info', data)
        } else {
            commit('set_info', '')
        }
    },
    get_zhuce({ commit, dispatch }) {
        const data = cache.getSession('zhuce')
        if (data) {
            commit('set_zhuce', data)
        } else {
            commit('set_zhuce', '')
        }
    }
}
const mutations = {
    set_login(state, val) {
        state.login = val
        cache.setSession('login', val)
    },
    set_info(state, val) {
        state.info = val
        cache.setSession('info', val)
    },
    set_authStatus(state, val) {
        state.authStatus = val
        cache.setSession('authStatus', val)
    },
    set_zhuce(state, val) {
        state.zhuce = val
        cache.setSession('zhuce', val)
    }
}

const getters = {
    infoMeta: state => {
        var data =  state.info
        // let data = JSON.parse(state.info)
        let sex = JSON.parse(data).sex?JSON.parse(data).sex.toString():''
        let birthday = JSON.parse(data).birthday?JSON.parse(data).birthday.substring(0,10):''
        // let birthday = JSON.parse(data).birthday.substring(0,10)
        let nickName =JSON.parse(data).nickName
        let address =JSON.parse(data).nickName
        let uAccounts = JSON.parse(data).nickName
        return {sex,birthday,nickName,address,uAccounts}
    }
}
export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})