<template>
    <div v-if="$route.path=='/home'||$route.path=='/game'||$route.path=='/user'||$route.path=='/mony'||$route.path=='/about'" class="nav clearfix">
      <div class="up-title">
        <div class="up-title-nav" v-show="!login">
          <router-link :to="{name:'home'}"><span>登录</span></router-link>
          <i>|</i>
          <span @click="zhuce">注册</span>
        </div>
        <div class="up-title-nav" v-show="login">
          <router-link :to="{name:'home'}"><span>{{login}}</span></router-link>
          <i>|</i>
          <span @click="exit">注销</span>
        </div>
      </div>
      <div class="container nav-header">
          <a href="" class="happy_logo"> </a>
           <div  style="position:relative;height:80px;">
            <ul class="navbar-navs">
                <li class="nav-item" v-for="item in list" :key="item.path">
                    <router-link :to="{name:item.path}">{{item.name}}</router-link>
                </li>
            </ul>
            <div v-show="login" style="height:50px;position: absolute;right: 0;line-height: 50px; color:red;">{{login}}&nbsp;&nbsp;欢迎光临！<a @click="exit">退出</a></div>
        </div>
      </div>
      <div v-if="zhucezhuce" class="zhuce" >
    </div>
    <div v-if="zhucezhuce"  class="zhucekuan">
        <div class="zhuce_news">
        <h1>快速注册</h1>
        <div id="contant" class="zhuce-title">
          <p  class="zhuce-title-1 active" @click="zhanghao(0)">帐号注册</p>
          <p  class="zhuce-title-1" @click="zhanghao(1)">手机注册</p>
        </div>
        <div v-if="content==0" class="zhuce-leirong">
          <div>
            <div class="news">
              <p>帐 &nbsp;&nbsp;&nbsp;号：</p>
              <Input v-model="zhangzhang" placeholder="请输入帐号" style="width: 200px" />
            </div>
            <div class="news">
              <p>密 &nbsp;&nbsp;&nbsp;码：</p>
              <Input v-model="zhangmima" type="password" placeholder="请输入密码" style="width: 200px" />
            </div>
            <div class="news">
              <p>姓 &nbsp;&nbsp;&nbsp;名：</p>
              <Input v-model="zhangxingm"  placeholder="请输入姓名" style="width: 200px" />
            </div>
            <div class="news">
              <p>身份证：</p>
              <Input v-model="zhangsfz"  placeholder="请输入姓名" style="width: 200px" />
            </div>
            <div class="news" style="width:200px;color:red;">
              <p style="width:100%;text-align:center;">{{warn}}</p>
            </div>
          </div>
          <div  class="submit">
            <p @click="quxiao">取消</p>
            <p @click="kszc">快速注册</p>
          </div>
        </div>
        <div v-if="content==1" class="zhuce-leirong">
           <div>
            <div class="news">
              <p>手机号：</p>
              <Input v-model="zhangzhang" placeholder="请输入帐号" style="width: 200px" />
            </div>
             <div class="news">
              <p>验证码：</p>
              <Input v-model="yzm" placeholder="请输入验证码" style="width: 100px" />
              <Button type="primary" @click="fama" v-bind:disabled="wwww" style="padding:0 5px;width:80px;margin-left:10px;">{{countdown}}</Button>
            </div>
            <div class="news">
              <p>密 &nbsp;&nbsp;&nbsp;码：</p>
              <Input v-model="zhangmima" type="password" placeholder="请输入密码" style="width: 200px" />
            </div>
            <div class="news">
              <p>姓 &nbsp;&nbsp;&nbsp;名：</p>
              <Input v-model="zhangxingm" placeholder="请输入姓名" style="width: 200px" />
            </div>
            <div class="news">
              <p>身份证：</p>
              <Input v-model="zhangsfz" placeholder="请输入身份证" style="width: 200px" />
            </div>
             <div class="news" style="width:200px;color:red;">
              <p style="width:100%;text-align:center;">{{warn}}</p>
            </div>
          </div>
          <div class="submit">
            <p @click="quxiao">取消</p>
            <p @click="kszc">快速注册</p>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import axios from "axios";
import base from "../api/base.js";
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["login"])
  },
  methods: {
    ...mapMutations(["set_login", "set_authStatus"]),
    exit() {
      this.set_login("");
      this.set_authStatus("");
    },
    cancel() {
      // this.$Message.info('Clicked cancel');
    },
    ok() {
      this.$refs["formInline2"].validate(valid => {
        let that = this;
        if (valid) {
          let data = {
            uAccounts: that.formInline2.user,
            uPass: that.formInline2.password
          };
          axios.get(base.api + "register", { params: data }).then(res => {
            if (res.data.code == "200") {
              this.$Message.info("注册成功!");
              this.set_login(that.formInline.user);
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "注册失败!");
            }
          });
        }
      });
    },
    fama() {
      this.wwww = true;
      if (this.zhangzhang == "") {
        this.warn = "账号为空";
        this.wwww = false;
        return;
      } else {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(this.zhangzhang)) {
          this.warn = "请输入正确的电话号码";
          this.wwww = false;
          return;
        } else {
          let data = {
            phone: this.zhangzhang
          };
          axios.get(base.api + "sendMess", { params: data }).then(res => {
            this.countdown = 60;
            this.jishi();
          });
        }
      }
    },
    quxiao() {
      this.zhucezhuce = false;
      this.zhangzhang = "";
      this.zhangmima = "";
      this.zhangxingm = "";
      this.zhangsfz = "";
      this.yzm = "";
    },
    kszc() {
      if (this.content == 0) {
        if (this.zhangzhang == "") {
          this.warn = "账号为空";
          return;
        }
        if (this.zhangmima == "") {
          this.warn = "密码为空";
          return;
        }
        if (this.zhangxingm == "") {
          this.warn = "姓名为空";
          return;
        } else {
          var str = this.zhangxingm;
          reg = /^([\u4e00-\u9fa5]){2,7}$/; //只能是中文，长度为2-7位
          if (!reg.test(str)) {
            this.warn = "姓名的字符串类型格式不正确!";
            return false;
          }
        }
        if (this.zhangsfz == "") {
          this.warn = "身份证为空";
          return;
        } else {
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (reg.test(this.zhangsfz) === false) {
            this.warn = "身份证格式不准确";
            return false;
          } else {
            let data = {
              uAccounts: this.zhangzhang,
              uPass: this.zhangmima,
              uName: this.zhangxingm,
              identityID: this.zhangsfz,
              type: 1
            };
            axios.get(base.api + "register", { params: data }).then(res => {
              if (res.data.code == "200") {
                this.warn = "";
                this.zhucezhuce = false;
                this.zhangzhang = "";
                this.zhangmima = "";
                this.zhangxingm = "";
                this.zhangsfz = "";
                this.yzm = "";
                this.set_login(this.zhangzhang);
              } else {
                this.zhucezhuce = true;
                this.warn = res.data.msg;
              }
            });
          }
        }
      }
      if (this.content == 1) {
        if (this.zhangzhang == "") {
          this.warn = "账号为空";
          return;
        } else {
          var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
          if (!myreg.test(this.zhangzhang)) {
            this.warn = "请输入正确的电话号码";
            return;
          } else {
            if (this.yzm == "") {
              this.warn = "请输入验证码";
              return;
            }
          }
        }
        if (this.zhangmima == "") {
          this.warn = "密码为空";
          return;
        }
        if (this.zhangxingm == "") {
          this.warn = "姓名为空";
          return;
        } else {
          var str = this.zhangxingm;
          reg = /^([\u4e00-\u9fa5]){2,7}$/; //只能是中文，长度为2-7位
          if (!reg.test(str)) {
            this.warn = "姓名的字符串类型格式不正确!";
            return false;
          }
        }

        if (this.zhangsfz == "") {
          this.warn = "身份证为空";
          return;
        } else {
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (reg.test(this.zhangsfz) === false) {
            this.warn = "身份证格式不准确";
            return false;
          } else {
            let data = {
              uAccounts: this.zhangzhang,
              uPass: this.zhangmima,
              uName: this.zhangxingm,
              identityID: this.zhangsfz,
              type: 2,
              sms: this.yzm
            };
            axios.get(base.api + "register", { params: data }).then(res => {
              if (res.data.code == "200") {
                this.warn = "";
                this.zhucezhuce = false;
                this.zhangzhang = "";
                this.zhangmima = "";
                this.zhangxingm = "";
                this.zhangsfz = "";
                this.yzm = "";
                this.set_login(this.zhangzhang);
              } else {
                this.zhucezhuce = true;
                this.warn = res.data.msg;
              }
            });
          }
        }
      }
    },
    zhanghao(index) {
      var contant = document.getElementsByClassName("zhuce-title-1");
      contant[0].classList.remove("active");
      contant[1].classList.remove("active");
      contant[index].classList.add("active");
      this.content = index;
      this.warn = "";
      this.zhangzhang = "";
      this.zhangmima = "";
      this.zhangxingm = "";
      this.zhangsfz = "";
      this.yzm = "";
    },
    zhuce() {
      this.content = 0;
      this.zhucezhuce = true;
    }
  },
  data() {
    return {
      // 账号注册
      wwww: false,
      countdown: "请发送验证码",
      warn: "",
      zhangzhang: "",
      zhangmima: "",
      zhangxingm: "",
      zhangsfz: "",
      yzm: "",
      value: "",
      content: 0,
      zhucezhuce: false,
      formInline: {
        user: "",
        password: ""
      },
      ruleInline: {
        user: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      formInline2: {
        user: "",
        password: ""
      },
      ruleInline2: {
        user: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      modal1: false,
      name: 0,
      list: [
        {
          name: "首页",
          path: "home"
        },
        {
          name: "游戏中心",
          path: "game"
        },
        {
          name: "用户中心",
          path: "user"
        },
        {
          name: "充值中心",
          path: "mony"
        },
        {
          name: "关于我们",
          path: "about"
        }
      ]
    };
  }
};
</script>

<style>
.up-title {
  width: 100%;
  height: 35px;
}
.up-title-nav {
  box-sizing: border-box;
  padding: 0 40px;
  margin: 0 auto;
  width: 1200px;
  height: 35px;
  background: #f0f0f0;
  text-align: right;
  line-height: 35px;
  color: #797979;
}
.up-title-nav span {
  cursor: pointer;
  font-size: 12px;
}
.up-title-nav span:last-child {
  color: #000;
}
.up-title-nav span:hover {
  color: #e60012;
}
.up-title-nav i {
  padding: 0 10px;
}
.nav {
  width: 100%;
  height: 126px;
  background: #fff;
  line-height: 85px;
}
.nav .nav-header {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.nav .container {
  padding: 0 !important;
}
.nav .happy_logo {
  display: block;
  margin-top: 16.5px;
  width: 145px;
  height: 52px;
  background: url("../assets/images/nav_logo_1.jpg") no-repeat 0 0;
}
.nav a {
  font-size: 16px;
  cursor: pointer;
  color: #000;
}
.navbar-navs {
  margin-left: 80px;
  width: 915px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-navs li {
  display: block;
  height: 88px;
  width: 88px;
  text-align: right;
}
.nav-item .router-link-active {
  color: red;
}
.bg-light {
  background-color: #000 !important;
}
</style>


