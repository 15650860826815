import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Home = (resole) => {
    import('../pages/home').then((module) => {
        resole(module)
    })
}

const User = (resole) => {
    import('../pages/user').then((module) => {
        resole(module)
    })
}
const Mony = (resole) => {
    import('../pages/mony').then((module) => {
        resole(module)
    })
}
const About = (resole) => {
    import('../pages/about').then((module) => {
        resole(module)
    })
}
const Game = (resole) => {
    import('../pages/game').then((module) => {
        resole(module)
    })
}
const Feedback = (resole) => {
    import('../pages/feedback').then((module) => {
        resole(module)
    })
}
const workers = (resole) => {
    import('../pages/workers').then((module) => {
        resole(module)
    })
}
const forget = (resole) => {
    import('../pages/forget').then((module) => {
        resole(module)
    })
}
const godownload = (resole) => {
    import('../pages/godownload').then((module) => {
        resole(module)
    })
}
const sgzdownload = (resole) => {
    import('../pages/sgzdownload').then((module) => {
        resole(module)
    })
}
const routes = [{
    path: '/',
    redirect: '/home'
},{
    name: 'home',
    path: '/home',
    component: Home
},{
    name: 'game',
    path: '/game',
    component: Game
},{
    name: 'user',
    path: '/user',
    component: User
},{
    name: 'mony',
    path: '/mony',
    component: Mony
},{
    name: 'about',
    path: '/about',
    component: About
},{
    name: 'feedback',
    path: '/feedback',
    component: Feedback
},{
    name: 'workers',
    path: '/workers',
    component: workers
},{
    name: 'forget',
    path: '/forget',
    component: forget
},{
    name: 'godownload',
    path: '/godownload',
    component: godownload
},{
    name: 'sgzdownload',
    path: '/sgzdownload',
    component: sgzdownload
}
]

const router = new Router({
    routes: routes
})


export default router