<template>
  <div id="app">
    <nav-header></nav-header>
    <router-view></router-view>
  </div>
</template>

<script>
import NavHeader from "./components/Nav";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "app",
  data() {
    return {
    }
  },
  components: {
    NavHeader
  },
  created() {
    this.get_login();
    this.get_authStatus();
    this.get_info();
  },
  methods: {
    ...mapActions(["get_login", "get_authStatus", "get_info"]),
  }
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  position: relative;
}
.zhuce {
  position: fixed;
  top: 0;
  background: #333;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.zhucekuan {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.zhuce_news {
  width: 400px;
  height: 550px;
  background: #fff;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 2px;
}
.zhuce_news h1 {
  font-size: 18px;
  line-height: 40px;
  padding-left: 10px;
}
.zhuce-title{
  width:100%;
  display: flex;
  flex-direction: row;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}
.zhuce-title p{
  width: 50%;
  text-align:center;
}
.active{
  color: red;
}
.news{
  width: 80%;
  margin:0 auto;
  margin-top:30px;
  display: flex;
  flex-direction: row;
  line-height: 30px;
}
.news p{
  padding: 0 10px;
}
.submit{
  width: 80%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin:0 auto;
  margin-top:40px;

}
.submit p{
  width: 80px;
  text-align: center;
  height: 40px;
  background: red;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.submit p:first-child{
  background: #ccc;
}
.submit p:last-child{
  background: red;
}
.submit p{
  line-height: 40px;
}
</style>

